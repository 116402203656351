import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import Layout from '../components/Layout';
import Link from '../components/Link';
import StationsThresholds from '../components/StationsThresholds';
import PrintButton from '../components/PrintButton';

const ThresholdsPage = () => {
  // Prepare filename for export.
  const today = new Date();
  const fileBaseName = `lvdlr_seuils_${today.toLocaleDateString()}`;

  return (
    <Layout noFooter title="Débits moyens journaliers">
      <Typography variant="body2" gutterBottom>
        <Link to="/">‹ Retour à la carte</Link>
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1">
            Débits moyens journaliers
          </Typography>
        </Grid>
        <Grid item>
          <PrintButton
            elementConfigurations={[
              { targetQuerySelector: 'h1', scale: 1.5 },
              { targetQuerySelector: '#thresholds-table', scale: 1.5 },
            ]}
            fileBaseName={fileBaseName}
          />
          <Typography variant="caption">
            Exporter le tableau
          </Typography>
        </Grid>
      </Grid>

      <StationsThresholds />
    </Layout>
  );
};

export default ThresholdsPage;
