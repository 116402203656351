import React from 'react';
import clsx from 'clsx';

import { Box, TableCell, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { thresholdColors } from '../helpers/commonHelpers';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    textAlign: 'right',
    fontSize: '0.8rem',
    fontWeight: theme.typography.fontWeightRegular,
  },
}))(Tooltip);

const noDataDisplay = '-';

const formatFlowValue = value => {
  // If the value is a number, truncate it to 2 decimals.
  // We're not rounding it up the regular way, as this can cause
  // the number to mismatch the given alert threshold level.
  // For instance: For a flow of 0.628 and a threshold at 0.63:
  // The value would get rounded up to 0.63, but the alert color wouldn't
  // match the threshold 0.63 at display time. So we're truncating instead.
  if (value === null) {
    return noDataDisplay;
  }

  return (Math.floor(value * 100) / 100).toFixed(2);
};

const useStyles = makeStyles(() => ({
  threshold: { whitespace: 'nowrap', verticalAlign: 'middle' },
  threshold0: { backgroundColor: thresholdColors[0] }, //       Qmj > doe
  threshold1: { backgroundColor: thresholdColors[1] }, // doe > Qmj > da
  threshold2: { backgroundColor: thresholdColors[2] }, // da  > Qmj > dar
  threshold3: { backgroundColor: thresholdColors[3], color: 'white' }, // dar > Qmj > dcr
  threshold4: { backgroundColor: thresholdColors[4], color: 'white' }, // dcr > Qmj
  noValue:    { backgroundColor: thresholdColors[5] },
}));

const StationsThresholdCell = ({
  flowValue,
  alertLevel,
  hasThresholds = false,
  tooltipContent,
}) => {
  const classes = useStyles();
  const value = formatFlowValue(flowValue);
  const noData = value === noDataDisplay;

  return (
    <TableCell
      align="center"
      className={clsx({
        [classes.threshold]: true,
        [classes[`threshold${alertLevel}`]]: hasThresholds && !noData,
        [classes.noValue]: noData,
      })}
    >
      <CustomTooltip title={tooltipContent}>
        <Box>{value}</Box>
      </CustomTooltip>
    </TableCell>
  );
};

export default React.memo(StationsThresholdCell);
