import React from 'react';

const StationsThresholdsTooltip = ({ pairs = {}, colors = ['#000'] }) => (
  <div>
    {Object.entries(pairs).map(([key, value], index) => (
      <div key={key} style={{ color: colors[index % colors.length] }}>
        {key} : {value.toFixed(2)}
      </div>
    ))}
  </div>
);

export default React.memo(StationsThresholdsTooltip);
