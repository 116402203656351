/* eslint-disable no-plusplus */
import { graphql, useStaticQuery } from 'gatsby';

export const useThresholds = () => {
  const { wrapper } = useStaticQuery(graphql`
    query {
      wrapper: allLastTenDaysQmj {
        nodes {
          startDate
          endDate
          code
          stationLabel
          subManagementUnitLabel
          exceeded
          QmJ {
            d
            v
            threshold
          }
          thresholds {
            doe
            da
            dar
            dcr
          }
          stationOrder
          subManagementUnitOrder
        }
      }
    }
  `);

  return wrapper.nodes;
};

export default useThresholds;
