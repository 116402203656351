import React from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { makeStyles } from '@material-ui/core/styles';

import {
  FormControlLabel,
  Switch,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from '@material-ui/core';

import useThresholds from '../hooks/useThresholds';
import StationsThresholdsRow from './StationsThresholdsRow';
import { sortBy, apiTimeFormat } from '../helpers/commonHelpers';

dayjs.extend(customParseFormat);

const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}));

const StationsThresholds = () => {
  const thresholds = useThresholds();
  const subManagementUnitAttributeName = 'subManagementUnitLabel';
  const classes = useStyles();
  const [shouldFilter, setShouldFilter] = React.useState(false);
  const handleOptionChange = event => setShouldFilter(event.target.checked);

  /**
   * Get number of columns from first element of threshold qmj array
   */
  const days = thresholds?.[0]?.QmJ?.map(({ d: date }) => ({
    dayjs: dayjs(date, apiTimeFormat, 'fr'),
    string: date,
  })) || [];

  const haveExceededThresholds = thresholds.some(({ exceeded }) => exceeded);

  const [orderReverse, setOrderReverse] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState('stationLabel');

  const handleSortBy = propertyName => () => {
    setOrderBy(propertyName);
    setOrderReverse(prevValue => (
      propertyName === orderBy ? !prevValue : false
    ));
  };

  const sortedThresholds = React.useMemo(
    () => thresholds.sort(sortBy(orderBy, orderReverse)),
    [orderBy, orderReverse, thresholds],
  );

  return (
    <>
      <Typography id="thresholds-display-options" variant="body2">
        <FormControlLabel
          label="Afficher uniquement les stations ayant dépassé un seuil"
          control={(
            <Switch checked={shouldFilter} onChange={handleOptionChange} />
          )}
        />
      </Typography>

      <TableContainer id="thresholds-table" component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'stationLabel'}
                  direction={orderBy === 'stationLabel' && orderReverse ? 'asc' : 'desc'}
                  onClick={handleSortBy('stationLabel')}
                >
                  Station
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === subManagementUnitAttributeName}
                  direction={orderBy === subManagementUnitAttributeName && orderReverse ? 'asc' : 'desc'}
                  onClick={handleSortBy(subManagementUnitAttributeName)}
                >
                  Sous-unité de gestion
                </TableSortLabel>
              </TableCell>
              {days.map(day => (
                <TableCell key={day.string} align="center">{day.dayjs.format('DD MMM')}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(shouldFilter && !haveExceededThresholds) && (
              <TableRow>
                <TableCell colSpan={days.length + 1} align="center">
                  <Typography variant="body2">
                    Aucune station n'a dépassé de seuil sur cette période
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {sortedThresholds.map(stationThresholds => (
              <StationsThresholdsRow
                key={stationThresholds.code}
                stationThresholds={stationThresholds}
                shouldFilter={shouldFilter}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(StationsThresholds);
