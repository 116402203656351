import React, { useMemo } from 'react';
import clsx from 'clsx';

import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Link from './Link';
import StationsThresholdCell from './StationsThresholdCell';
import StationsThresholdsTooltip from './StationsThresholdsTooltip';

import { thresholdColors } from '../helpers/commonHelpers';

const useStyles = makeStyles(() => ({
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#aaaaaa11',
    },
  },
  hiddenTableRow: {
    display: 'none',
  },
}));

const getTooltipContent = (tooltip, hasThresholds) => {
  if (!hasThresholds) {
    return 'Aucun seuil défini pour cette station';
  }

  return tooltip;
};

const StationsThresholdsRow = ({
  shouldFilter,
  stationThresholds: {
    exceeded,
    subManagementUnitLabel,
    code,
    stationLabel,
    QmJ,
    thresholds = { doe: null, da: null, dar: null, dcr: null },
  },
}) => {
  const classes = useStyles();
  const hasThresholds = Boolean(
    Object.values(thresholds).reduce((acc, curr) => acc + curr),
  );
  const thresholdsArray = [...Object.values(thresholds), -Infinity];
  const tooltipContent = useMemo(() =>
    getTooltipContent(
      <StationsThresholdsTooltip
        pairs={thresholds}
        colors={thresholdColors}
      />,
      hasThresholds,
    ),
    [thresholds, hasThresholds, thresholdColors]);

  return (
    <TableRow
      key={code}
      className={clsx({
        [classes.tableRow]: true,
        [classes.hiddenTableRow]: (shouldFilter && !exceeded),
      })}
    >
      <TableCell component="th" scope="row">
        <Link to={`/station/${code}`}>
          {stationLabel || code}
        </Link>
      </TableCell>

      <TableCell component="th" scope="row">
        {subManagementUnitLabel || '-'}
      </TableCell>

      {QmJ.map(({ v: flow, d: date }) => (
        <StationsThresholdCell
          key={date}
          flowValue={flow}
          alertLevel={thresholdsArray.findIndex(threshold => flow >= threshold)}
          hasThresholds={hasThresholds}
          tooltipContent={tooltipContent}
        />
      ))}
    </TableRow>
  );
};

export default React.memo(StationsThresholdsRow);
